<script lang="ts">
	import { classes } from '@thearc-hq/ui-kit/utils';
	import Button from '$lib/components/Button/Button.svelte';

	export let disabled: boolean | undefined = undefined;
	export let buttonRef: HTMLButtonElement | undefined = undefined;
	let className: string | undefined = '';

	export { className as class };
</script>

<Button
	on:click
	bind:buttonRef
	{...$$restProps}
	class={classes(
		'h-12 border border-transparent text-light-white bg-accent-primary uppercase',
		!disabled &&
			'hover:border-accent-hover hover:text-accent-hover hover:bg-white active:bg-accent-pressed active:text-light-white active:outline-none',
		className
	)}
	{disabled}
>
	<slot name="icon" slot="icon" />
</Button>
